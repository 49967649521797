export class KeyListener {
  public element: HTMLElement;
  private keysPressed: Set<string>;
  private listeners: Map<string, (() => void)[]>;

  public constructor(element: HTMLElement) {
    this.element = element;
    this.keysPressed = new Set();
    this.listeners = new Map<string, (() => void)[]>();

    this.element.setAttribute("tabindex", "0");

    this.element.addEventListener("keyup", (e) => {
      this.execute();
      this.keysPressed.delete(e.key);
    });

    this.element.addEventListener("keydown", (e) => {
      this.keysPressed.add(e.key);
    });
  }

  /**
   * Keyboard shortcut listener
   * @param keys
   * @param callback
   * @returns
   */
  public on(keys: string | string[], callback: () => void): KeyListener {
    for (const key of keys.toString().toLowerCase().split(",").sort()) {
      const listener = this.listeners.get(key);

      if (listener) {
        listener.push(callback);
      } else {
        this.listeners.set(key.split(" ").sort().join(" "), [callback]);
      }
    }
    return this;
  }

  /**
   * Remove a keyboard shortcut
   * @param keys
   */
  public remove(...keys: string[]): void {
    const key = keys.toString().toLowerCase().split(",").sort();
    this.listeners.delete(key.join(" "));
  }

  /**
   * Run callback dependend on keyboard shortcut
   */
  private execute(): void {
    const pressed = Array.from(this.keysPressed).sort();
    if (pressed.length) {
      const callbacks = this.listeners.get(pressed.join(" ").toLowerCase());

      if (callbacks) {
        for (const exec of callbacks) {
          exec();
        }
      }
    }
  }
}
