<div class="checkbox-container" [formGroup]="group">
  @for (option of options; track $index) {
    <div [ngClass]="[direction]">
      <span class="input-container">
        <mat-checkbox [formControlName]="option.label">
          <span [innerHTML]="option.label | translate | iconReplace"></span>
        </mat-checkbox>
      </span>
    </div>
  }
</div>
