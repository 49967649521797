import { AsyncPipe, DatePipe } from "@angular/common";
import { HttpClient, provideHttpClient, withInterceptors } from "@angular/common/http";
import { ApplicationConfig, importProvidersFrom } from "@angular/core";
import { MAT_CHECKBOX_DEFAULT_OPTIONS } from "@angular/material/checkbox";
import {
  DateAdapter,
  ErrorStateMatcher,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatNativeDateModule,
  ShowOnDirtyErrorStateMatcher,
} from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MAT_ICON_DEFAULT_OPTIONS } from "@angular/material/icon";
import { MAT_RADIO_DEFAULT_OPTIONS } from "@angular/material/radio";
import { MAT_SLIDE_TOGGLE_DEFAULT_OPTIONS } from "@angular/material/slide-toggle";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { provideAnimations } from "@angular/platform-browser/animations";
import { provideRouter } from "@angular/router";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { CustomDateAdapter } from "src/adapters/custom-date-adapter";
import { DeactivateGuard } from "src/guards/deactivate.guard";
import { IsAuthenticatedGuard } from "src/guards/is-authenticated.guard";
import { IsNotAuthenticatedGuard } from "src/guards/is-not-authenticated.guard";
import { beforeSendInterceptor } from "src/interceptors/beforeSendInterceptor";
import { SsoModule } from "src/modules/sso.module";
import { ExtractHtmlPipe } from "src/pipes/extract-html.pipe";
import { ROUTES } from "src/routes/app.routes";
import { ApplicationService } from "src/services/application.service";
import { graphqlProvider } from "./graphql.provider";

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(ROUTES),
    provideHttpClient(withInterceptors([beforeSendInterceptor])),
    provideAnimations(),
    importProvidersFrom(
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: createTranslateLoader,
          deps: [HttpClient],
        },
      }),
      MatDialogModule,
      MatSnackBarModule,
      MatDatepickerModule,
    ),
    DeactivateGuard,
    {
      provide: MAT_ICON_DEFAULT_OPTIONS,
      useValue: {
        fontSet: "material-icons-outlined",
      },
    },
    { provide: MAT_DATE_FORMATS, useClass: MatNativeDateModule },
    { provide: DateAdapter, useClass: CustomDateAdapter, deps: [MAT_DATE_LOCALE, ApplicationService] },
    { provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher },
    { provide: MAT_CHECKBOX_DEFAULT_OPTIONS, useValue: { color: "primary" } },
    { provide: MAT_RADIO_DEFAULT_OPTIONS, useValue: { color: "primary" } },
    { provide: MAT_SLIDE_TOGGLE_DEFAULT_OPTIONS, useValue: { color: "primary" } },
    DatePipe,
    AsyncPipe,
    ExtractHtmlPipe,
    IsAuthenticatedGuard,
    IsNotAuthenticatedGuard,
    importProvidersFrom(SsoModule),
    provideHttpClient(),
    graphqlProvider,
  ],
};

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}
