import { Component } from "@angular/core";
import { ALLOWED_CHARACTERS } from "src/config/allowed-characters.config";
import { PrefixTextComponent } from "../../prefix-text.component";
import { TemplateTextComponent } from "../../templates/template-text/template-text.component";
import { MaxLength } from "../../types/MaxLength";

@Component({
  standalone: true,
  imports: [TemplateTextComponent],
  selector: "app-prefix-oms",
  templateUrl: "./prefix-oms.component.html",
  styleUrl: "./prefix-oms.component.less",
})
export class PrefixOmsComponent extends PrefixTextComponent {
  public maxlength: MaxLength;

  public constructor() {
    super();
    this.maxlength = 30;
    this.characters = ALLOWED_CHARACTERS["oms"];
  }
}
