import { AbstractControl, ValidatorFn } from "@angular/forms";
import { ELEVENPROOF_CONFIGS } from "src/config/elevenproof.config";
import { CONFIG_REGEX } from "src/config/regexs.config";
import { ElevenProof } from "src/enums/elevenproof";
import { GridValue } from "../../default/form/form.interface";
import { ThreatLevel } from "./prefix.component";

export abstract class PrefixValidator {
  /**
   * Validates minimal length of control
   * @param value
   * @returns
   */
  public static minlength(value: number | null): ValidatorFn {
    return (control: AbstractControl) => {
      let valid = true;
      if (value != null && control.value) {
        valid = value <= control.value.length;
      }
      return {
        minlength: {
          type: valid ? ThreatLevel.VALID : ThreatLevel.ERROR,
          value,
        },
      };
    };
  }

  /**
   * Validates minimal length of control with array value
   * @param value
   * @returns
   */
  public static minArrayLength(value: number | null): ValidatorFn {
    return (control: AbstractControl) => {
      let valid = true;
      if (value != null && control.value) {
        valid = value <= control.value.length;
      }
      return {
        minArrayLength: {
          type: valid ? ThreatLevel.VALID : ThreatLevel.ERROR,
          value,
        },
      };
    };
  }

  /**
   * Validates maximal length of control
   * @param value
   * @returns
   */
  public static maxlength(value: number | null): ValidatorFn {
    return (control: AbstractControl) => {
      let valid = false;
      if (value != null) {
        if (control.value && value !== Infinity) {
          valid = control.value.length <= value;
        } else {
          valid = true;
        }
      }
      return {
        maxlength: {
          type: valid ? ThreatLevel.VALID : ThreatLevel.ERROR,
          value,
        },
      };
    };
  }

  /**
   * Validates maximal length of control with array value
   * @param value
   * @returns
   */
  public static maxArrayLength(value: number | null): ValidatorFn {
    return (control: AbstractControl) => {
      let valid = false;
      if (value != null) {
        if (control.value && value !== Infinity) {
          valid = control.value.length <= value;
        } else {
          valid = true;
        }
      }
      return {
        maxArrayLength: {
          type: valid ? ThreatLevel.VALID : ThreatLevel.ERROR,
          value,
        },
      };
    };
  }

  /**
   * Validates minimal value of control
   * @param value
   * @returns
   */
  public static min(value: number | null): ValidatorFn {
    return (control: AbstractControl) => {
      let valid = false;

      if (value != null && control.value) {
        const controlvalue = parseFloat(control.value?.replace(",", "."));
        if (!isNaN(controlvalue)) valid = controlvalue >= value;
      } else {
        valid = true;
      }

      return {
        min: {
          type: valid ? ThreatLevel.VALID : ThreatLevel.ERROR,
          value,
        },
      };
    };
  }

  /**
   * Validates maximal value of control
   * @param value
   * @returns
   */
  public static max(value: number | null): ValidatorFn {
    return (control: AbstractControl) => {
      let valid = false;

      if (value != null && control.value) {
        const controlvalue = parseFloat(control.value?.replace(",", "."));
        if (!isNaN(controlvalue)) valid = controlvalue <= value;
      } else {
        valid = true;
      }

      return {
        max: {
          type: valid ? ThreatLevel.VALID : ThreatLevel.ERROR,
          value,
        },
      };
    };
  }

  /**
   * Validates minimal value of control
   * @param value
   * @returns
   */
  public static minDate(value: string): ValidatorFn {
    return (control: AbstractControl) => {
      let valid = false;

      if (value != null && control.value) {
        const date = new Date(control.value);
        const check = String(date) !== "Invalid Date";
        if (check) valid = date >= new Date(value);
      } else {
        valid = true;
      }

      return {
        minDate: {
          type: valid ? ThreatLevel.VALID : ThreatLevel.ERROR,
          value,
        },
      };
    };
  }

  /**
   * Validates maximal value of control
   * @param value
   * @returns
   */
  public static maxDate(value: string): ValidatorFn {
    return (control: AbstractControl) => {
      let valid = false;

      if (value != null && control.value) {
        const date = new Date(control.value);
        const check = String(date) !== "Invalid Date";
        if (check) valid = date <= new Date(value);
      } else {
        valid = true;
      }

      return {
        maxDate: {
          type: valid ? ThreatLevel.VALID : ThreatLevel.ERROR,
          value,
        },
      };
    };
  }

  /**
   * Validates control based on a predefined regExp
   * @param value
   * @returns
   */
  public static regex(value: keyof typeof CONFIG_REGEX | null): ValidatorFn {
    return (control: AbstractControl) => {
      let valid = true;
      if (value) {
        const regex = CONFIG_REGEX[value] || null;
        if (regex) {
          if (control.value) valid = regex.test(control.value);
        } else {
          throw new Error(`Cannot find regex => ${value}`);
        }
      }
      return {
        regex: {
          type: valid ? ThreatLevel.VALID : ThreatLevel.ERROR,
          value,
        },
      };
    };
  }

  public static time(): ValidatorFn {
    return (control: AbstractControl) => {
      let valid = true;
      const pattern = /^[0-9]{2}:[0-9]{2}$/;
      if (control.value) valid = pattern.test(control.value);
      return {
        time: {
          type: valid ? ThreatLevel.VALID : ThreatLevel.ERROR,
        },
      };
    };
  }

  /**
   * Validates control on required
   * @returns
   */
  public static required(): ValidatorFn {
    return (control: AbstractControl) => {
      return {
        required: {
          type: control.value ? ThreatLevel.VALID : ThreatLevel.ERROR,
        },
      };
    };
  }

  /**
   * BSN validator
   * @returns
   */
  public static bsn(): ValidatorFn {
    return PrefixValidator.eleven(ElevenProof.BSN);
  }

  /**
   * Validates control based on rrn
   * https://nl.wikipedia.org/wiki/Rijksregisternummer
   * @returns
   */
  public static rrn(): ValidatorFn {
    return (control: AbstractControl<string>) => {
      let valid = true;

      const value = control.value;
      if (/^\d{11}$/.test(value)) {
        valid = false;
        const modFunction = (value: number): number => {
          return 97 - (value % 97);
        };
        //Haal de laatste 2 cijfers weg.
        const checkDigit = Number(value.substring(9));
        let nrToCheck = Number(value.substring(0, 9));

        //Check zonder jaar 2000.
        if (modFunction(nrToCheck) === checkDigit) {
          valid = true;
        } else {
          //Check voor jaar 2000 +
          nrToCheck = Number("2" + value.substring(0, 9));
          valid = modFunction(nrToCheck) === checkDigit ? true : false;
        }
      }

      return {
        rrn: {
          type: valid ? ThreatLevel.VALID : ThreatLevel.ERROR,
        },
      };
    };
  }

  /**
   * Validates control based on one option active
   * @param options
   * @returns
   */
  public static optionsRequired(): ValidatorFn {
    return (control: AbstractControl<GridValue[]>) => {
      const valid = control.value.filter((option) => option.checked).length > 0;
      return {
        optionsRequired: {
          type: valid ? ThreatLevel.VALID : ThreatLevel.ERROR,
        },
      };
    };
  }

  /**
   * Validates control based on one option active
   * @param options
   * @returns
   */
  public static optionsOne(): ValidatorFn {
    return (control: AbstractControl<GridValue[]>) => {
      const valid = control.value.filter((option) => option.checked).length <= 1;
      return {
        optionsOne: {
          type: valid ? ThreatLevel.VALID : ThreatLevel.ERROR,
        },
      };
    };
  }

  /**
   * Validates control based on all options active
   * @param options
   * @returns
   */
  public static optionsAll(): ValidatorFn {
    return (control: AbstractControl<GridValue[]>) => {
      const valid = control.value.every((option) => option.checked);
      return {
        optionsAll: {
          type: valid ? ThreatLevel.VALID : ThreatLevel.ERROR,
        },
      };
    };
  }

  /**
   * Validates control based on the eleven test
   * https://nl.wikipedia.org/wiki/Elfproef
   * @returns
   */
  private static eleven(type: ElevenProof): ValidatorFn {
    return (control: AbstractControl<string | null>) => {
      let valid = true;
      const config = ELEVENPROOF_CONFIGS[type];
      const value = control.value;
      if (config && value && /^\d{9}$/.test(value)) {
        const values = value.split("").map((v: string) => Number(v));
        const sum = config.multipliers.map((multiplier, index) => multiplier * values[index]).reduce((prev, cur) => prev + cur);
        valid = sum % config.divider === 0;
      }
      return {
        eleven: {
          type: valid ? ThreatLevel.VALID : ThreatLevel.ERROR,
        },
      };
    };
  }
}
