import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { Router } from "@angular/router";
import { firstValueFrom } from "rxjs";
import { ErrorMessageComponent } from "src/app/components/global/snackbar/impl/error-message/error-message.component";
import { SnackbarService } from "src/app/components/global/snackbar/snackbar.service";

export interface HttpObserve<T = unknown> {
  headers: HttpHeaders;
  body: T;
}

@Injectable({
  providedIn: "root",
})
export class HttpService {
  private http: HttpClient;
  private snackbar: SnackbarService;
  private router: Router;

  public constructor() {
    this.http = inject(HttpClient);
    this.snackbar = inject(SnackbarService);
    this.router = inject(Router);
  }

  /**
   * Retrieve data from url
   * @param url
   * @param data
   * @returns
   */
  public async retrieve<T = unknown>(url: string, data: unknown, options: Parameters<typeof this.http.get>[1] = {}): Promise<T> {
    try {
      const response = await firstValueFrom(
        this.http.get<T>(`${url}?${new URLSearchParams(<Record<string, string>>data).toString()}`, {
          ...options,
          observe: "response",
        }),
      );

      this.handleStatus(response);

      if (response.body) {
        return response.body;
      } else {
        throw new Error(`Invalid response from [retrieve=${url}]`);
      }
    } catch (error) {
      this.onError(error);
      throw error;
    }
  }

  /**
   * Send data to url
   * @param url
   * @param data
   * @returns
   */
  public async send<T = unknown>(url: string, data: unknown, options: Parameters<typeof this.http.post>[2] = {}): Promise<T> {
    try {
      const response = await firstValueFrom(
        this.http.post<T>(url, data, {
          ...options,
          observe: "response",
        }),
      );

      this.handleStatus(response);

      if (response.body) {
        return response.body;
      } else {
        throw new Error(`Invalid response from [send=${url}]`);
      }
    } catch (error) {
      this.onError(error);
      throw error;
    }
  }

  /**
   * send formdata to url
   * @param url
   * @param data
   * @param headers
   * @returns
   */
  public async sendForm<T = unknown>(url: string, data: Record<string, unknown>, headers = {}): Promise<T> {
    const formdata = new FormData();
    for (const [key, value] of Object.entries(data)) formdata.set(key, <string | Blob>value);
    try {
      const response = await firstValueFrom(
        this.http.post<T>(url, formdata, {
          headers,
          observe: "response",
        }),
      );

      this.handleStatus(response);

      if (response.body) {
        return response.body;
      } else {
        throw new Error(`Invalid response from [sendForm=${url}]`);
      }
    } catch (error) {
      this.onError(error);
      throw error;
    }
  }

  private onError(error: unknown): void {
    console.error("Failed to load result => ", error);
    this.snackbar.open<string>(ErrorMessageComponent, "ERRORS.RESPONSE");
  }

  private handleStatus(res: HttpResponse<unknown>): void {
    switch (res.status) {
      case 200:
        this.onStatus200();
        break;

      case 401:
        this.onStatus401();
        break;

      case 500:
        this.onStatus500();
        break;

      default:
        console.error(`[HTTP] Unhandled status code: ${res.status}`);
        break;
    }
  }

  private onStatus200(): void {
    //do nothing on 200 :)
  }

  private onStatus401(): void {
    this.router.navigate(["logout"]);
    this.snackbar.open<string>(ErrorMessageComponent, "ERRORS.UNAUTHORIZED");
  }

  private onStatus500(): void {
    this.onError("[HTTP] Fatal Server Error");
    this.router.navigate(["/app/home/"]);
  }
}
