import { Injectable } from "@angular/core";
import { KeyListener } from "../classes/KeyListener";

@Injectable({
  providedIn: "root",
})
export class ShortcutService {
  public keylistener: KeyListener;
  public actions: (string | (() => void))[][];

  public constructor() {
    this.keylistener = new KeyListener(document.body);
    this.actions = [];
  }

  public add(keys: string, method: () => void): void {
    this.keylistener.on(keys, method);
    this.actions.push([keys, method]);
  }
}
