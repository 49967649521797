import { Route } from "@angular/router";
import { AuthScreenComponent } from "src/app/auth/auth.component";
import { LoginComponent } from "src/app/components/default/login/login.component";
import { LoggedOutComponent } from "src/app/logged-out/logged-out.component";
import { RecoveryComponent } from "src/app/recovery/recovery.component";
import { IsNotAuthenticatedGuard } from "../guards/is-not-authenticated.guard";

export const ROUTES: Route[] = [
  {
    path: "app",
    loadChildren: () => import("./secure.routes").then((mod) => mod.SECURE_ROUTES),
  },
  {
    path: "",
    component: AuthScreenComponent,
    children: [
      {
        path: "",
        pathMatch: "full",
        redirectTo: "login",
      },
      {
        path: "login",
        canActivate: [IsNotAuthenticatedGuard],
        children: [
          {
            path: ":id",
            component: LoginComponent,
          },
          {
            path: "",
            component: LoginComponent,
          },
        ],
      },
      {
        path: "logout",
        component: LoggedOutComponent,
      },
      {
        path: "recover",
        canActivate: [IsNotAuthenticatedGuard],
        component: RecoveryComponent,
      },
    ],
  },
];
