import { Component, EventEmitter, Inject, InjectionToken, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { FormControl, TouchedChangeEvent } from "@angular/forms";
import { filter } from "rxjs";
import { DefaultComponent } from "src/app/default.component";
export const DEFAULT_VALUE = new InjectionToken("options");

export enum PrefixEventType {
  HIGHLIGHT = "HIGHLIGHT",
}

export interface PrefixEvent {
  type: PrefixEventType;
  params: unknown[];
}

export enum ThreatLevel {
  VALID,
  WARNING,
  ERROR,
}

export type Threat = [string, { type: ThreatLevel; value?: unknown }];
export interface PrefixOptions<CONTROL = unknown> {
  updateOn: FormControl["updateOn"];
  defaultValue: CONTROL;
}

@Component({
  standalone: true,
  selector: "app-prefix",
  template: "",
})
export class PrefixComponent<CONTROL, VALUE = CONTROL> extends DefaultComponent implements OnInit, OnChanges {
  public control: FormControl<CONTROL | null>;

  @Input()
  public value: VALUE | null;

  @Input()
  public characters: string | null;

  @Input()
  public hide: boolean;

  @Input()
  public post: boolean;

  @Input()
  public disabled: boolean;

  @Input()
  public required: boolean;

  @Input()
  public label: string | null;

  @Output()
  public valuechanged: EventEmitter<VALUE>;

  @Output()
  public hidechanged: EventEmitter<boolean>;

  @Output()
  public requiredchanged: EventEmitter<boolean>;

  @Output()
  public postchanged: EventEmitter<boolean>;

  @Output()
  public threatchanged: EventEmitter<Threat>;

  @Output()
  public touchedchanged: EventEmitter<boolean>;

  @Output()
  public controlchanged: EventEmitter<FormControl<CONTROL | null>>;

  @Output()
  public event: EventEmitter<PrefixEvent>;

  /**
   *
   * @param value Default control value
   */
  public constructor(@Inject(DEFAULT_VALUE) options: Partial<PrefixOptions<CONTROL | null>> = {}) {
    super();

    const settings: PrefixOptions<CONTROL | null> = {
      defaultValue: null,
      updateOn: "change",
      ...options,
    };

    const control = new FormControl(settings.defaultValue, {
      updateOn: settings.updateOn,
    });

    this.control = control;
    this.value = null;
    this.characters = null;
    this.hide = false;
    this.post = true;
    this.disabled = false;
    this.required = false;
    this.label = null;

    this.valuechanged = new EventEmitter();
    this.hidechanged = new EventEmitter();
    this.requiredchanged = new EventEmitter();
    this.postchanged = new EventEmitter();
    this.threatchanged = new EventEmitter();
    this.controlchanged = new EventEmitter();
    this.touchedchanged = new EventEmitter();
    this.event = new EventEmitter();

    control.valueChanges.subscribe((value) => this.onControlValue(value));
    control.events.pipe(filter((e) => e instanceof TouchedChangeEvent)).subscribe((event) => {
      this.touchedchanged.emit(event.touched);
    });

    this.checkThreats();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes["value"]) {
      this.updateControl(changes["value"].currentValue, false);
    }
  }

  public ngOnInit(): void {
    this.setInitialControlValue(this.value);

    this.hidechanged.emit(this.hide);
    this.requiredchanged.emit(this.required);
    this.postchanged.emit(this.post);
    this.controlchanged.emit(this.control);
  }

  /**
   * Force update value
   * @param value
   */
  public updateValue(value: CONTROL | null): void {
    this.value = <VALUE>value;
    this.valuechanged.emit(this.value);
  }

  /**
   * Force update control's value
   * @param value
   */
  public updateControl(value: VALUE | null, emit: boolean = true): void {
    this.control.setValue(<CONTROL>value, {
      emitEvent: emit,
    });
  }

  /**
   * Fires on control value change
   * @param value
   */
  protected onControlValue(value: CONTROL | null): void {
    this.updateValue(value);
    this.checkThreats();
  }

  /**
   * Set intial value of control
   */
  protected setInitialControlValue(value: VALUE | null): void {
    if (value) this.updateControl(value);
  }

  private checkThreats(): void {
    if (this.control.errors) {
      const threats = Object.entries(this.control.errors);
      for (const [name, threat] of threats) {
        this.threatchanged.emit([name.toUpperCase(), threat]);
      }
    }
  }
}
